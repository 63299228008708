import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Frequently Asked Questions</h3>
          <p>
            <strong>
              What happened at the Oxton and Manor House assisted living
              facilities (ALFs)?
              <br />
            </strong>
            On January 20, 2017, the Securities and Exchange Commission filed a
            Complaint in the United States District Court for the District of
            New Jersey against Dwayne Edwards, Todd Barker, and various other
            individuals and entities, including Oxton Senior Living, LLC and
            several related entities operating in the form of assisted living
            facilities (ALFs). The Court entered, among other orders, an Order
            Appointing Receiver on January 20, 2017 (the “Receivership Order”).
            Derek Pierce of Healthcare Management Partners, LLC has been
            appointed the receiver (the “Receiver”) in this matter over the
            Receivership Entities (as defined in the Receivership Order which
            can be found at insert link to the Receivership Order).
          </p>

          <p>
            <strong>
              What is a receiver?
              <br />
            </strong>
            A receiver is an individual appointed by a court to control and
            administer assets when there is a danger that, in the absence of
            such an appointment, the assets might be lost, removed, or injured.
            In this case, the court has taken control over the Receivership
            Entities away from the defendants and given control over the
            Receivership Entities to the Receiver.
          </p>
          <p>
            <strong>
              Can I see the Complaint and Orders?
              <br />
            </strong>
            Yes. These can be found on the Key{" "}
            <Link target="_blank" to="/key-court-documents">
              Court Documents page.
            </Link>
          </p>

          <p>
            <strong>
              I am a bondholder of one of the Receivership Entities, will I get
              all of my money back?
              <br />
            </strong>
            It is too soon to determine how much investors will receive or when.
            The Receiver is working to protect and liquidate the Receivership
            Entities’ assets, but there is no way to predict at this early date
            how much money will be returned to bondholders or when that will
            happen.
          </p>

          <p>
            <strong>
              Should I hire a lawyer?
              <br />
            </strong>
            While we cannot provide you legal advice, you certainly have the
            right to hire or consult with your own lawyer. As authorized by the
            Court, however, the Receiver will be investigating all potential
            claims or methods of recovery that he can pursue for the benefit of
            bondholders.
          </p>

          <p>
            <strong>
              Is the Receiver my lawyer?
              <br />
            </strong>
            No. The Receiver is an agent of the Court and is effectuating the
            Court’s orders. The Receiver will, however, be investigating
            potential claims or methods of recovery that he can pursue for the
            benefit of bondholders.
          </p>

          <p>
            <strong>
              I am a vendor to one or more of the ALFs with open, unpaid
              invoices, will I be paid?
              <br />
            </strong>
            At this point, the disbursement of funds from the Receivership
            entities will be made in a way that most protects and preserves the
            assets of the receivership estate. The Receivership Order provides
            for giving first priority to the repayment of secured indebtedness
            of the Receivership Entities, and the remainder disbursed according
            to further order of the Court. In the coming months, it is possible
            that the Receiver will establish a claims process to allow vendors
            to make claims on the assets of the Receivership Entities. If that
            occurs, the Receiver will post instructions for making a claim on
            this website.
          </p>

          <p>
            <strong>
              How long does the receivership process normally take?
              <br />
            </strong>
            There is no set timeline.
          </p>

          <p>
            <strong>
              How can I stay informed of progress in this case?
              <br />
            </strong>
            The Receiver has established a special website,{" "}
            <Link
              target="_blank"
              to="http://www.oxtonseniorlivingreceivership.com"
            >
              www.oxtonseniorlivingreceivership.com
            </Link>
            , that will be updated from time to time.
          </p>

          <p>
            <strong>
              What does the Receiver plan to do with the Receivership Entities?
              <br />
            </strong>
            The Receiver plans to continue operating the ALFs until they can be
            sold.
          </p>

          <p>
            <strong>
              How do I submit a claim?
              <br />
            </strong>
            There is no claims process at this time.
          </p>

          <p>
            <strong>
              If I have other questions, how can I get them answered?
              <br />
            </strong>
            You may contact the Receiver via email at{" "}
            <a href="mailto:oxton.receivership@hcmpllc.com">
              oxton.receivership@hcmpllc.com
            </a>
            .
          </p>
        </div>
      </Layout>
    )
  }
}
